<template>
  <div>
    <associatedOption
      type="combobox"
      :fieldAttributes="{ ...fieldAttributes, associatedOption: options }"
      :value="localValue"
      v-on="$listeners"
      @keyup="getRemoteOptions"
      @input="revalidateAndSend"
      :field="field"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";

export default {
  data() {
    return { options: this.fieldAttributes.associatedOption };
  },
  components: { associatedOption },
  props: ["result", "field", "fieldAttributes","value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  //   asyncComputed: {async options() {

  //        return await getConnectionInfo(this, this.value );

  //   } },
  methods: {
    revalidateAndSend(value) {
      this.$emit("input", value);
      this.$emit("revalidate");
    },
    getRemoteOptions(event) {
      let ignoredKeys = [13, 38, 40, 39, 37, 27, 32, 17, 18, 16];
      if (!ignoredKeys.includes(event.keyCode)) {
        //console.log(event);
        // let $source = $(this);
        //let $fieldsObject = $source.closest(".fields");
        //let url = $fieldsObject.attr("action");
        //showLoading();
        // let value = $source.val();
        let value = event.target.value;
        // if(value.length>2){
        let caller = this;
        caller.$emit("input", value);
        caller.frameworkAxiosRequest({
          method: 'post',
          url: caller.result.tableUrl,
          data: {
            requestType: 'ajax',
            function: 'getVendorList',
            vendorID: value,
          },
        })
            .then(function (response) {
            //handle success
            //  console.log(response.data.result.table);

            // caller.setTableContentCallBack();

            caller.options = response.data.result.json.vendorsList;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
        // $.ajax({
        //   type: "POST",
        //   url: url,
        //   data: { requestType: "ajax", function: "getVendorList", vendorID: value },
        //   dataType: "json",
        //   success: function (data) {
        //     let $selector = $fieldsObject.find("select.vendorListCombo");
        //     let $comboSelector = $fieldsObject.find(
        //       ".custom-combobox-input.vendorListCombo"
        //     );
        //     $selector.html(produceOptions(data.vendorsList, ""));
        //     $comboSelector.autocomplete("search");
        //   },
        // });
        //s  }
      }
     
    },
  },
};
</script>